import { createSlice } from '@reduxjs/toolkit';
const initialState = {
flags:[],
constant:[],
shippingConstant:[],
referlength:0,
updateReferral:0,
balances:[],
searchCountires:"",
referralcode:'',
isbottom:false,
locationconstat:[],
showOffer:true


};


const FlagsSlice = createSlice({
  name: 'flags',
  initialState,
  productbrandlist:'',
  reducers: {

  SetFlags : (state, { payload }) => {
   state.flags=payload
  },

  SetConstants : (state, { payload }) => {
    state.constant=payload
   },
   GetBalances : (state, { payload }) => {
    state.balances=payload
   },
   SetShippingConstants : (state, { payload }) => {
    state.shippingConstant=payload
   },
   SetLocationConstants : (state, { payload }) => {
    state.locationconstat=payload
   },
   SetReferlength: (state, { payload }) => {
    state.referlength=payload
   },
   SetUpdateReferral: (state, { payload }) => {
    state.updateReferral=payload
   },
   SetSearchCountires: (state, { payload }) => {
    state.searchCountires=payload
   },
   SetReferralcode: (state, { payload }) => {
    state.referralcode=payload
   },
   SetIsBottom: (state, { payload }) => {
    state.isbottom=payload
   },
   SetProductBrandList: (state, { payload }) => {
    state.productbrandlist=payload
   },
   SetShowOffer: (state, { payload }) => {
    state.showOffer=payload
   },

},

});

export const { SetFlags,SetIsBottom,SetProductBrandList,SetReferralcode, SetLocationConstants,SetConstants,SetShippingConstants,SetSearchCountires,SetUpdateReferral,SetReferlength,GetBalances,SetShowOffer} =
FlagsSlice.actions;

export default FlagsSlice.reducer;
