import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { rootReducer } from "./root-reducer";
import hardSet from 'redux-persist/lib/stateReconciler/hardSet';

// Middleware to disable console.log in production
const disableConsoleLogInProduction = store => next => action => {
  // Instead of redefining console.log, we'll just skip logging in production
  if (process.env.REACT_APP_ENV !== 'production') {
    console.log(action); // Only log in non-production environments
  }
  return next(action);
};

// const expirationMiddleware = (store) => (next) => (action) => {
//   const expirationTime = new Date("2024-01-25").getTime();
//   const currentTime = Date.now();

//   if (currentTime >= expirationTime) {
//     const state = store.getState();
//     if (!state.flags.showOffer) {
//       store.dispatch({ type: "flags/SetShowOffer", payload: true });
//     }
//   }
//   return next(action);
// }

const persistConfig = {
  key: "root",
  storage,
  //stateReconciler: hardSet,
  //defaultState:{orders:[]},
  //blacklist: ['topsearches'] // navigation will not be persisted
  //whitelist: ['navigation'] // only navigation will be persisted
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  }).concat(disableConsoleLogInProduction),
});

export const persistor = persistStore(store);