import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import "./assets/Font/Montserrat/Montserrat-Light.ttf";
import "./assets/Font/Montserrat/Montserrat-Light.ttf";
import "./assets/Font/Montserrat/Montserrat-Bold.ttf";
import "./assets/Font/Montserrat/Montserrat-Regular.ttf";
import "./assets/Font/Montserrat/Montserrat-Bold.ttf";
import "./assets/Font/Montserrat/Montserrat-ExtraBold.ttf";
import "./assets/Font/Montserrat/Montserrat-ExtraLight.ttf";
import "./assets/Font/Helvetica-Bold-Font.ttf";
import "./assets/Font/CormorantMediumItalic.ttf";
import "./assets/Font/Cormorant-MediumItalic.ttf";
import icon from "./assets/images/icon.png";

import "./assets/Font/RaconteurNF.ttf";
import "./assets/Font/Cinzel.ttf";
import "./assets/Font/Helvetica4.ttf";
// import loading from "./assets/images/loading.gif"
import { useTranslation } from "react-i18next";
//import PaymentMethod from "./Page/PaymentMethod";
//import CreditCard from "./Page/CreditCard";
//import Notifacation from "./Page/Static/Notifacation";
import { useEffect, Suspense, lazy, useState } from "react";
import {
  db,
  collection,
  onSnapshot,
  query,
  where,
  doc,
} from "./utils/firebase/firebase.utils";
import {
  GetCurrency,
  SelectedCurrencies,
  GetCurrencyDefault,
  GetCountryCode,
} from "./features/Currency/CurrencySlice";
import { GetAllMyFollowers, GetAllProduct, GetAllUsers,GetAllProductRejPen,GetAllUsersTrusted, LoadingNumber,  } from "./features/Products/ProductSlice";
import { GetAllProductViewd } from "./features/RecentlyViewed/RecentlyViewed";
import { useDispatch } from "react-redux";
import axios from "axios";
import { useContext } from "react";
import { UserContext } from "./context/user.context";
import { SetConstants, SetFlags,SetProductBrandList } from "./features/Flags/FlagsSlice";
import { Helmet, HelmetProvider } from "react-helmet";
import { getDocs, limit, orderBy } from "firebase/firestore";
// import ReferProgram from "./Page/ReferalProgram/ReferProgram";
// import Invite from "./Page/ReferalProgram/Invite";
//import HomePage from "./Page/HomePage";
//import Yayoi from "./Page/Blogs/Yayoi";
//import FashionIndustry from "./Page/Blogs/FashionIndustry";
//import CareHandbags from "./Page/Blogs/CareHandbags";
//import HouseofGucci from "./Page/Blogs/HouseofGucci";
//import SellingPrelovedClothes from "./Page/Blogs/SellingPrelovedClothes";
//import CloseUp from "./Page/Blogs/CloseUp";
//import Profile from "./Page/Profile";
// const HomePage = lazy(()=> import("./Page/HomePage"));
const HomePage = lazy(()=> import("./Page/HomePage"));
const Profile = lazy(()=> import("./Page/Profile"));
const Yayoi = lazy(()=> import("./Page/Blogs/Yayoi"));
const FashionIndustry = lazy(()=> import("./Page/Blogs/FashionIndustry"));
const CareHandbags = lazy(()=> import("./Page/Blogs/CareHandbags"));
const HouseofGucci = lazy(()=> import("./Page/Blogs/HouseofGucci"));
const SellingPrelovedClothes = lazy(()=> import("./Page/Blogs/SellingPrelovedClothes"));
const CloseUp = lazy(()=> import("./Page/Blogs/CloseUp"));
const Aboutus = lazy(() => import("./Page/Static/Aboutus"));
const Privacy = lazy(() => import("./Page/Static/Privacy"));
const Payments = lazy(() => import("./Page/Payments"));
const PaymentSuccess = lazy(() => import("./Page/Static/PaymentSuccess"));
const PaymentFailed = lazy(() => import("./Page/Static/PaymentFailed"));
const PaymentCancelled = lazy(() => import("./Page/Static/PaymentCancelled"));
const Product = lazy(() => import("./Page/Product"));
const QualityControl = lazy(() => import("./Page/Static/QualityControl"));
const ReturnPolicy = lazy(() => import("./Page/Static/ReturnPolicy"));
const TermsConditions = lazy(() => import("./Page/Static/TermsConditions"));
const Orders = lazy(() => import("./Page/Orders"));
const MyOrders = lazy(() => import("./Page/MyOrders"));
const MyBag = lazy(() => import("./Page/MyBag"));
const ProductDetails = lazy(() => import("./Page/OrderDetails"));
//const HomePage = lazy(() => import("./Page/HomePage"));
const Luxuries = lazy(() => import("./Page/Blogs/Luxuries"));
const Burberry = lazy(() => import("./Page/Blogs/Burberry"));
const Shipping = lazy(() => import("./Page/Static/Shipping"));
const Hermesbag = lazy(() => import("./Page/Blogs/Hermesbag"));
const PradaReedition = lazy(() => import("./Page/Blogs/PradaReedition"));
const BestInvestmentBag = lazy(() => import("./Page/Blogs/BestInvestmentBag"));
const Arablooks = lazy(() => import("./Page/Blogs/Arablooks"));
const HowToSell = lazy(() => import("./Page/Static/HowToSell"));
const SustainableFashion = lazy(() =>
  import("./Page/Blogs/SustainableFashion")
);
const Team = lazy(() => import("./Page/Static/Team"));
const Likes = lazy(() => import("./Page/Likes"));
const HowItWork = lazy(() => import("./Page/Static/HowItWork"));
const JoinUs = lazy(() => import("./Page/Static/JoinUs"));
const AllBlog = lazy(() => import("./Page/Blogs/AllBlog"));
const Contact = lazy(() => import("./Page/Static/Contact"));
const Brand = lazy(() => import("./Page/Brand"));
const AutMarissa = lazy(() => import("./Page/Blogs/AutMarissa"));
const NotFound = lazy(() => import("./Page/NotFound"));
const IssusFashioneLitist = lazy(() => import("./Page/Blogs/IssusFashioneLitist"));
const ChanelPriceIncrease = lazy(() => import("./Page/Blogs/ChanelPriceIncrease"));
const WhyShiftfromfashion = lazy(() => import("./Page/Blogs/WhyShiftfromfashion"));
const SustainableMiddleEast = lazy(() => import("./Page/Blogs/SustainableMiddleEast"));
const AiWeb = lazy(() => import("./Page/Blogs/AiWeb"));
const NextInFashionS2 = lazy(() => import("./Page/Blogs/NextInFashionS2"));
const Eid = lazy(() => import("./Page/Blogs/Eid"));
const HowToSellLuxuriesOnLibas = lazy(() => import("./Page/Blogs/HowToSellLuxuriesOnLibas"));
const Faq = lazy(() => import("./Page/Static/Faq"));
const FightFastFashion = lazy(() => import("./Page/Blogs/FightFastFashion"));
const Whatisquietluxury = lazy(() => import("./Page/Blogs/Whatisquietluxury"));
const TomFordReleases = lazy(() => import("./Page/Blogs/TomFordReleases"));
const PrincessDiana = lazy(() => import("./Page/Blogs/PrincessDiana"));
const ReferenceGuidetoLV = lazy(() => import("./Page/Blogs/ReferenceGuidetoLV"));
const LebaneseDesigners = lazy(() => import("./Page/Blogs/LebaneseDesigners"));
const SustainableStars = lazy(() => import("./Page/Blogs/SustainableStars"));
const Howtosellclothes = lazy(() => import("./Page/Blogs/Howtosellclothes"));
const AdhaEid = lazy(() => import("./Page/Blogs/AdhaEid"));
const CelineTriomphe = lazy(() => import("./Page/Blogs/CelineTriomphe"));
const Vintage = lazy(() => import("./Page/Blogs/Vintage"));
const InspiringARevolution = lazy(() => import("./Page/Blogs/InspiringARevolution"));
const DesignerClothingAuthentication = lazy(() => import("./Page/Blogs/DesignerClothingAuthentication"));
const Barbie = lazy(() => import("./Page/Blogs/Barbie"));
const AuthenticateRolex = lazy(() => import("./Page/Blogs/AuthenticateRolex"));
const StyleGucciDianabag = lazy(() => import("./Page/Blogs/StyleGucciDianabag"));
const SustainableFashionBooms = lazy(() => import("./Page/Blogs/SustainableFashionBooms"));
const  ReferProgram= lazy(() => import("./Page/ReferalProgram/ReferProgram"));
const  Invite= lazy(() => import("./Page/ReferalProgram/Invite"));
const  ThanksForSharing= lazy(() => import("./Page/ReferalProgram/ThanksForSharing"));
const  PodcastCEOOne= lazy(() => import("./Page/Blogs/PodcastCEOOne"));
const  MustSeeCelebrities= lazy(() => import("./Page/Blogs/MustSeeCelebrities"));
const  EssentialOffice= lazy(() => import("./Page/Blogs/EssentialOffice"));
const  HermesKellyBag= lazy(() => import("./Page/Blogs/HermesKellyBag"));
const  InspiringEmiratiWomen= lazy(() => import("./Page/Blogs/InspiringEmiratiWomen"));
const  RalphLauren= lazy(() => import("./Page/Blogs/RalphLauren"));
const  RealvsFake= lazy(() => import("./Page/Blogs/RealvsFake"));
const  TakeCareOfYourChanel= lazy(() => import("./Page/Blogs/TakeCareOfYourChanel"));
const  FallTrends= lazy(() => import("./Page/Blogs/FallTrends"));
const  ASLEAI= lazy(() => import("./Page/Blogs/ASLEAI"));
const  UncoverRare= lazy(() => import("./Page/Blogs/UncoverRare"));
const  Arabic1= lazy(() => import("./Page/Blogs/Arabic1"));
const  LandingPage= lazy(() => import("./Page/LandingPage"));

function App() {
  const { i18n } = useTranslation();
  const { currentUser } = useContext(UserContext);
  const dispatch = useDispatch();
  const currency = JSON.parse(localStorage.getItem("currency"));
  const collRef = collection(db, "currency");
  const flag = collection(db, "flags");
  const USERS = collection(db, "users");
  const USERSTrusted = query(
    collection(db, "users"),
    where("userStatus", "==", "Trusted"),
    limit(10)
  );;

  // const productRef = query(
  //   collection(db, "product"),
  //   where("status", "!=", "Deleted")
  // );
  document.body.dir = i18n.dir();
  const constantRef = doc(db, "constants", "KyQsqHn7mZzwPUHPst1B");
  const PRODUCTBRANDSLIST = doc(db, "constants", "PRODUCTBRANDSLIST");

  // const getOnlineJustViewed= async () => {
  //   try {
  
  //     var viewed = collection(
  //       db,
  //       "profiles",
  //       currentUser.uid,
  //       "recentlyViewedProducts"
  //     );
  //     var q = query(viewed, orderBy("date", "desc"));
  //     var firstDocumentSnapshots = [];

  //     const firstQuery = query(
  //         q, 
  //       limit(50));
        
  //       firstDocumentSnapshots = await getDocs(firstQuery);
  //       const data = firstDocumentSnapshots.docs.map((doc) => (
  //          doc.id
          
  //       ));
  //       dispatch(
  //         GetAllProductViewd(
  //          data

  //         )
  //       );
       
  //   } catch (e) {
  //     console.error(e);
  //   }
        
  // };

  useEffect(() => {
  onSnapshot(PRODUCTBRANDSLIST, (snapshot) => {
    dispatch(SetProductBrandList(snapshot.data()));
  });
  onSnapshot(constantRef, (snapshot) => {
    dispatch(SetConstants(snapshot.data()));
  });
    // onSnapshot(productRef, (snapshot) => {
    //   dispatch(
    //     GetAllProductRejPen(
    //       snapshot.docs
    //         .filter((docc) => docc.data().status ==  "Rejected")
           
    //         .map((doc) => ({
    //           ...doc.data(),
    //           id: doc.id,
    //         }))
    //     )
    //   );
    // });
   
    dispatch(
      GetAllProduct([]))
    // onSnapshot(productRef, (snapshot) => {
    //   dispatch(
    //     GetAllProduct(
    //       snapshot.docs
    //         .filter((docc) => docc.data().status == "Live")
    //         .concat(
    //           snapshot.docs.filter(
    //             (docc) =>
    //               docc.data().status != "Rejected" &&
    //               docc.data().status != "Pending" &&
    //               docc.data().status != "Live"
    //           )
    //         )
    //         .map((doc) => ({
    //           ...doc.data(),
    //           id: doc.id,
    //         }))
    //     )
    //   );
    // });
    if (currentUser != null) {
      // getOnlineJustViewed()
      // const viewed = collection(
      //   db,
      //   "profiles",
      //   currentUser.uid,
      //   "recentlyViewedProducts"
      // );
      // const queryviewed = query(viewed, limit(50));

      
      // onSnapshot(queryviewed, (snapshot) => {
      //   dispatch(
      //     GetAllProductViewd(
      //       snapshot.docs.map((doc) => ({
      //         ...doc.data(),
      //         id: doc.id,
      //       }))
      //     )
      //   );
      // });
      // const followingQuery = query(
      //   collection(db, "following"),
      //   where("idFollowFrom", "==", currentUser.uid)
      // );
      // onSnapshot(followingQuery, (snapshot) => {
      //   dispatch(
      //     GetAllMyFollowers(
      //       snapshot.docs.map((doc) => ({
      //         ...doc.data(),
      //         id: doc.id,
      //       }))
      //     )
      //   );
      // });
    }
    // onSnapshot(USERS, (snapshot) => {
    //   dispatch(
    //     GetAllUsers(
    //       snapshot.docs.map((doc) => ({
    //         ...doc.data(),
    //         id: doc.id,
    //       }))
    //     )
    //   );
    // });
    dispatch(GetAllUsers([]));
    onSnapshot(USERSTrusted, (snapshot) => {
      dispatch(
        GetAllUsersTrusted(
          snapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }))
        )
      );
    });
    onSnapshot(collRef, (snapshot) => {
      dispatch(
        GetCurrency(
          snapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }))
        )
      );
    });
    onSnapshot(flag, (snapshot) => {
      dispatch(
        SetFlags(
          snapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }))
        )
      );
    });
    // axios
    //   .get(`http://ip-api.com/json`)
    //   .then((res) => {
    //     if (res.data.countryCode === "AE") {
    //       dispatch(GetCurrencyDefault("papAwH0XoKC3AmT9YmR7"));
    //     } else {
    //       dispatch(GetCurrencyDefault("1YiC1Sz9MkYPY3LG8MlN"));
    //     }
    //   })
    //   .catch((error) => {
    //     console.log("error", error);
    //     dispatch(GetCurrencyDefault("papAwH0XoKC3AmT9YmR7"));
    //   });

    axios
      .get(`https://geolocation-db.com/json/`)
      .then((res) => {
        dispatch(GetCountryCode(res.data.country_code));
        if (res.data.country_code === "AE") {
          dispatch(GetCurrencyDefault("papAwH0XoKC3AmT9YmR7"));
        } else {
          dispatch(GetCurrencyDefault("1YiC1Sz9MkYPY3LG8MlN"));
        }
      })
      .catch((error) => {
        console.log("error", error);
        dispatch(GetCurrencyDefault("papAwH0XoKC3AmT9YmR7"));
        dispatch(GetCountryCode("AE"));
      });

    // getLocation().then((code)=>{
    //           if (code === "AE") {
    //       dispatch(GetCurrencyDefault("papAwH0XoKC3AmT9YmR7"));
    //     } else {
    //       dispatch(GetCurrencyDefault("1YiC1Sz9MkYPY3LG8MlN"));
    //     }
    // }).catch((error)=>{
    //           console.log("error", error);
    //     dispatch(GetCurrencyDefault("papAwH0XoKC3AmT9YmR7"));
    // })
    dispatch(SelectedCurrencies(currency));
    // dispatch(SetSignIn(false))
    // dispatch(SetSignUpp(false))

    // window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, []);

  const currentUrl = window.location.href;
  return (
    // <div className="loading-center">
    //   <img src={loading} className="loading" />
    // </div>
    <>
        <Helmet>
        <meta
          name="description"
          content="Explore the first online community-powered marketplace for buying and selling second-hand clothes and preloved luxury. Join our community today!
          "
        />
    <title>Libas: Buy & sell pre-owned designer bags, shoes, clothes</title>
    <meta property="og:title" content="Buy and Sell Luxury Fashion | Libas" />

        <meta property="og:description" content="Explore the first online community-powered marketplace for buying and selling second-hand clothes and preloved luxury. Join our community today!" />
        <meta property="og:image" content={icon} />
        <meta property="og:url" content={currentUrl} />
</Helmet>
    <BrowserRouter>
      <Suspense
        fallback={
          // <>
          // </>
          // <div className="loading-center">
          //   <img src={loading} className="loading" />
          // </div>
          <div className="loading-center">
          <div className="loader2 ">
          <span className="loader__element"></span>
          <span className="loader__element"></span>
          <span className="loader__element"></span>
        </div>
        </div>
        }
      >
        <Routes>
        <Route path="*" element={<NotFound />} />
          <Route path="/" index element={<LandingPage />} />
          <Route path="/About-US" element={<Aboutus />} />
          <Route path="/Privacy-policy" element={<Privacy />} />
          <Route path="/payment" element={<Payments />} />
          <Route path="/products" element={<Product />} />
          <Route path="/Quality-Control" element={<QualityControl />} />
          <Route path="/Return-Policy" element={<ReturnPolicy />} />
          <Route path="/Terms-Conditions" element={<TermsConditions />} />
          <Route path="/order-details" element={<ProductDetails />} />
          <Route path="/checkout" element={<MyBag />} />
          <Route path="/product/:id" element={<Orders />} />
          <Route path="/my-orders" element={<MyOrders />} />
          <Route path="/how-to-sell" element={<HowItWork />} />
          <Route path="/Join-Us" element={<JoinUs />} />
          <Route path="/wishlist" element={<Likes />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/our-team" element={<Team />} />
       
          <Route path="/help-center" element={<Faq />} />
          <Route path="/Shipping-Policy" element={<Shipping />} />
          <Route path="/all-blogs" element={<AllBlog />} />
          <Route path="/ChanelPriceIncrease2023" element={<ChanelPriceIncrease />} />
          <Route path="/Whytheshiftfromfashionentertainment" element={<WhyShiftfromfashion />} />
          <Route path="/SustainablefashionintheMiddleEast" element={<SustainableMiddleEast />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/WhyisthePradaReeditioniconic" element={<PradaReedition />}/>
          <Route path="/Issustainablefashionelitist" element={<IssusFashioneLitist />}/>

          <Route path="/2023BestInvestmentBags" element={<BestInvestmentBag />} />
          <Route path="/YayoiKusamaAndLouisVuitton2023" element={<Yayoi />} />
          <Route path="/HowResaleImprovesFashionsFuture" element={<FashionIndustry />}/>
          <Route path="/Howtocareforyourhandbags" element={<CareHandbags />} />
          <Route path="/Guccihistorybetweenyesterdayandtomorrow" element={<HouseofGucci />}/>
          <Route path="/sellpreloveddesignerluxuriestips"element={<SellingPrelovedClothes />}/>
          <Route path="/WhatYouNeedForASustainableWardrobe"element={<CloseUp />} />
          <Route path="/profile/:id" element={<Profile />} />
          <Route path="/Bestdressed2022Arablooks" element={<Arablooks />} />
          <Route path="/StoryoftheHermesbag" element={<Hermesbag />} />
          <Route  path="/Getting-Into-Sustainable-Fashion"  element={<SustainableFashion />}/>
          <Route path="/howtospotrealvsfakeBurberry" element={<Burberry />} />
          <Route path="/Ourcommunitysmusthaveluxuries" element={<Luxuries />} />
          <Route path="/Whyfightfastfashion" element={<FightFastFashion />} />

          <Route path="/Brands" element={<Brand />} />
          {/* <Route path="/SignUp" element={<SignUp />} /> */}

          {/* <Route path="/my-adresses" element={<MyAdress />} /> */}
          {/* <Route path="/notification" element={<Notifacation />} />
          <Route path="/payment-method" element={<PaymentMethod />} />
          <Route path="/credit-card" element={<CreditCard />} /> */}
          <Route
            path="/AuthenticationwithMarissaRaad"
            element={<AutMarissa />}
          />
          <Route path="/Shipping-Policy" element={<Shipping />} />
          <Route path="/Payment-Success" element={<PaymentSuccess />} />
          <Route path="/Payment-Failed" element={<PaymentFailed />} />
          <Route path="/Payment-Cancelled" element={<PaymentCancelled />} />
          <Route path="/FashiontechnologyintheMENAregion" element={<AiWeb />} />
          <Route path="/NextInFashionS2EP3BestLooks" element={<NextInFashionS2 />} />
          <Route path="/BestEidGiftIdeas" element={<Eid />} />
          <Route path="/MaximizeProfitsSellingFashionLuxuriesOnLibas" element={<HowToSellLuxuriesOnLibas />} />
          <Route path="/Whatisquietluxury" element={<Whatisquietluxury />} />
          <Route path="/TomFordReleasesFinalWomenswearCollection" element={<TomFordReleases />} />
          <Route path="/PrincessDiana-and-LadyDiorbaghistory" element={<PrincessDiana />} />
          <Route path="/ReferenceGuidetoLouisVuittonBags" element={<ReferenceGuidetoLV />} />
          <Route path="/LebaneseDesignersLooksatCannesRedCarpet" element={<LebaneseDesigners />} />
          <Route path="/6Starsrewearingtheirredccarpetlooksforsustainablefashion" element={<SustainableStars />} />
          <Route path="/Howtosellclothesonlineandmakecashtoday" element={<Howtosellclothes />} />
          <Route path="/12BestSecondHandLuxuryEidalAdha2023Gifts" element={<AdhaEid />} />
          <Route path="/WhytheCelineTriompheisspecialandwhycelebsloveit" element={<CelineTriomphe />} />
          <Route path="/VintageFashionStoryPopularityandImpaconModernStyle" element={<Vintage />} />
          <Route path="/Ourfashionsustainabilitypledge" element={<InspiringARevolution />} />
          <Route path="/WhyluxuryresaleneedsAItechnology" element={<DesignerClothingAuthentication />} />
          <Route path="/Margot-robbie-barbie-press-looks-best-5-outfits" element={<Barbie />} />
          <Route path="/how-to-authenticate-a-used-Rolex-by-Libas" element={<AuthenticateRolex />} />
          <Route path="/how-to-style-the-Gucci-Diana-bag" element={<StyleGucciDianabag />} />
          <Route path="/sustainable-fashion-booms-in-Dubai" element={<SustainableFashionBooms />} />
          <Route path="/sustainable-fashion-and-business-podcast-with-CEO" element={<PodcastCEOOne />} />
          <Route path="/5-celebrities-dressed-by-Arab-fashion-designers" element={<MustSeeCelebrities />} />
          <Route path="/twelve-essential-office-wear-for-women" element={<EssentialOffice />} />
          <Route path="/6-strategies-to-care-for-the-Hermes-Kelly-bag" element={<HermesKellyBag />} />
          <Route path="/5-inspiring-khaleeji-women-and-their-best-fashion-moments" element={<InspiringEmiratiWomen />} />
          <Route path="/Ralph-Lauren-spring-2024-nyfw-show" element={<RalphLauren />} />
          <Route path="/gucci-horsebit-bag-real-vs-fake-guide" element={<RealvsFake />} />
          <Route path="/six-tips-you-didnt-know-on-how-to-take-care-of-your-chanel-bag" element={<TakeCareOfYourChanel />} />
          <Route path="/fall-2023-trends" element={<FallTrends />} />
          <Route path="/Libas-revolutionizes-fashion-with-disruptive-ASLE-AI-tool" element={<ASLEAI />} />
          <Route path="/uncover-rare-and-vintage-designer-finds-on-Libas" element={<UncoverRare />} />
          <Route path="/What-is-fast-fashion-and-what-negative-impact-does-it-bring" element={<Arabic1 />} />

          <Route path="/referral-program-Libas" element={<ReferProgram />} />
          <Route path="/Referrals-Invited-Friends" element={<Invite />} />
          <Route path="/Thanks-For-Sharing-Libas" element={<ThanksForSharing />} />
          {/* <Route path="/refer" element={<ReferProgram />} />
          <Route path="/invite" element={<Invite />} /> */}

        </Routes>
      </Suspense>
      <Routes>
        {/* <Route path="/SignUp" element={<SignUp />} />
        <Route path="/SignIn" element={<SignIn />} />
        <Route path="/SignInWithGoogle" element={<SignInWithGoogleModal />} /> */}

        {/* <Route path="/sell-with-us"  element={<SellWithUs/>}/>
        <Route path="/first-step"  element={<SellFirstStep/>}/>
      <Route path="/category"  element={<CategorySells/>}/>
      <Route path="/brand"  element={<BrandSells/>}/>
      <Route path="/colors"  element={<Colors/>}/>
      <Route path="/image"  element={<ImagesSell/>}/>
      <Route path="/models"  element={<ModelsSells/>}/>
      <Route path="/sizes"  element={<SizeSells/>}/> */}
      </Routes>
    </BrowserRouter>

    </>
  );
}

export default App;



